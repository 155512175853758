import React from 'react';
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import styled from "styled-components";

import NewsCardDate from "../components/News/NewsCardDate";

import { showDateString } from "../features/Helpers/formatDate";
import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";

const IMAGE_STYLES = {
  height: '520px',
  width: '520px',
}

const breadcrumbsFactory = new BreadcrumbsFactory();

const StyledContainer = styled.div`

`;

const NewsItem = ({ pageContext }) => {
  const { title, date, content, slug } = pageContext;
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'novosti',
      title: 'Новости',
    },
    {
      path: `/novosti/${slug}`,
      title,
    },
  ]);

  return (
    <Layout crumbs={ crumbs }>
      <SEO title={ title } />
      <StyledContainer className="container">
        <h1>{ title }</h1>
        <NewsCardDate date={ showDateString(date) } />
        { pageContext.image && <Img className="image" style={IMAGE_STYLES} fluid={ pageContext.image.fluid } /> }
        <div className="content" dangerouslySetInnerHTML={ {__html: content}} />
      </StyledContainer>
    </Layout>
  );
};

export default NewsItem;